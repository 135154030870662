/* eslint-disable no-use-before-define */
// eslint-disable-next-line no-restricted-imports
import _ from 'underscore';
// @ts-expect-error - TS7016 - No TS for 'legacy/button_util'
import ButtonUtil from 'legacy/button_util';
import Flash from 'legacy/flash';
import $ from 'legacy/jquery';
import { Validate } from 'legacy/validation';
import Rollbar from 'shared/utils/rollbar_logger';
import t from 'shared/utils/translation';
import { assignLocation } from '../shared/utils/navigation';

export type Method = 'get' | 'post' | 'del' | 'put';
type InternalMethod = 'GET' | 'POST' | 'DELETE' | 'PUT';

type Response<ResponseExtensions = object> = ResponseExtensions & {
  goto?: string;
  message?: string;
  status: 'success' | 'error';
};

type XHRResponse<ResponseExtensions = object> = {
  responseJSON?: Response<ResponseExtensions>;
};

type AjaxBaseOptions<
  ResponseExtensions = object,
  CustomHandlers = object
> = CustomHandlers & {
  always?: (response?: Response<ResponseExtensions>) => void;
  buttonSelector?: string | JQuery | null;
  dataType?: string | null;
  disableFlash?: boolean;
  disableLock?: boolean;
  disablePending?: boolean;
  error?: (
    response?: Response<ResponseExtensions>,
    xhr?: XHRResponse<ResponseExtensions>
  ) => void;
  errorMessage?: string | null;
  lockId?: string;
  pendingMessage?: string | null;
  redirect?: boolean;
  scrollAfterValidation?: boolean;
  success?: (response: Response<ResponseExtensions>) => void;
  successMessage?: string | null;
  url?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validator?: any;
};

type AjaxMethodOptions<
  ResponseExtensions = object,
  CustomHandlers = object
> = AjaxBaseOptions<ResponseExtensions, CustomHandlers> & {
  url: string;
  async?: boolean;
  data?: Record<string, unknown>;
  formSelector?: string;
  json?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validator?: any;
};

type AjaxSubmitOptions<
  ResponseExtensions = object,
  CustomHandlers = object
> = AjaxBaseOptions<ResponseExtensions, CustomHandlers> & {
  formSelector: string;
};

type AjaxUnionOptions<ResponseExtensions, CustomHandlers> =
  | AjaxMethodOptions<ResponseExtensions, CustomHandlers>
  | AjaxSubmitOptions<ResponseExtensions, CustomHandlers>;

type AjaxParams<ResponseExtensions> = {
  dataType: string;
  success: (response: Response<ResponseExtensions>) => void;
  error: (response: XHRResponse<ResponseExtensions>) => void;
  async?: boolean;
  contentType?: string;
  data?: string | Record<string, unknown>;
  type?: InternalMethod;
  url?: string;
};

/**
 * Utility methods which encapsulate the standard behavior for AJAX requests. This includes:
 *
 *   1) prohibiting duplicate concurrent requests with a lock on the form/url being submitted
 *   2) showing a pending message
 *   2) handling errors with a flash message
 *   3) handling redirects on success if the response indicates to do so
 *
 * There are three flavors you can invoke, each which accepts a hash of options (a few of which are required):
 *
 * Ajax.get:     Call this method when you want to do a GET request.
 *
 *               Ajax.get({
 *                 url: '/path'
 *               });
 *
 * Ajax.post:    Call this method when you want to post to a url with arbitrary data you have in an object.
 *
 *               Ajax.post({
 *                 url: '/path'      // required
 *                 data: { x: 'y' }  // optional; an object to post
 *                 json: true        // optional; defaults to false, meaning the data object will be posted
 *                                   //           as standard form params. If true, the object will be posted
 *                                   //           as JSON instead. The main reason to do this is if you need
 *                                   //           nulls and empty arrays to be serialized.
 *               });
 *
 * Ajax.submit:  Call this method when you want a form on the page to be submitted as-is but via AJAX.
 *
 *               Ajax.submit({
 *                 formSelector: '#myform'  // required
 *               });
 *
 * For all 3 calls, you may also pass these optional parameters:
 *   buttonSelector          selector of the button which should be visually disabled during the request
 *   validator               a function that returns an array of validations (booleans)
 *   scrollAfterValidation   defaults to true
 *
 *   pendingMessage          defaults to 'Saving'; pass a different message or null to disable
 *   successMessage          defaults to no message, unless a message was returned by the server to show
 *   errorMessage            defaults to 'Sorry, we encountered an error. Please try again.', unless a
 *                           message was returned by the server to show
 *   disableFlash            defaults to false; if true, disables the default pending/error messages
 *   disableLock             defaults to false; if true, ignore our AJAX locks and just do it
 *
 *   dataType                the type of response you expect; defaults to json, or you may specify html
 *
 *   success                 callback on success
 *   error                   callback on error
 *   always                  callback which happens after success or error
 *   <foobar>                callback; if the AJAX response object has `status: foobar`, this will be called
 */
const Ajax = (function () {
  const locks: { [lockKey: string]: boolean } = {};

  function get<ResponseExtensions = object, CustomHandlers = object>(
    options: AjaxMethodOptions<ResponseExtensions, CustomHandlers>
  ) {
    doMethod(options, 'GET');
  }

  function post<ResponseExtensions = object, CustomHandlers = object>(
    options: AjaxMethodOptions<ResponseExtensions, CustomHandlers>
  ) {
    doMethod(options, 'POST');
  }

  function del<ResponseExtensions = object, CustomHandlers = object>(
    options: AjaxMethodOptions<ResponseExtensions, CustomHandlers>
  ) {
    doMethod(options, 'DELETE');
  }

  function put<ResponseExtensions = object, CustomHandlers = object>(
    options: AjaxMethodOptions<ResponseExtensions, CustomHandlers>
  ) {
    doMethod(options, 'PUT');
  }

  function doMethod<ResponseExtensions = object, CustomHandlers = object>(
    options: AjaxMethodOptions<ResponseExtensions, CustomHandlers>,
    method: InternalMethod
  ) {
    validateOption('url', options);
    options.lockId = options.url;

    execute(options, function () {
      const params = createAjaxParams(options);
      params.type = method;
      params.url = options.url;

      if (options.async === false) {
        params.async = false;
      }

      if (options.json === true) {
        params.contentType = 'application/json';
        params.data = JSON.stringify(options.data);
      } else {
        params.data = options.data;
      }

      $.ajax(params);
    });
  }

  /**
   * @param {Object} options see comments at top of file
   * @param {Object} ajaxParams params to pass to ajaxSubmit
   */
  function submit<ResponseExtensions = object, CustomHandlers = object>(
    options: AjaxSubmitOptions<ResponseExtensions, CustomHandlers>,
    ajaxParams?: AjaxParams<ResponseExtensions>
  ) {
    validateOption('formSelector', options);
    options.lockId = options.formSelector;

    execute(options, function () {
      const params = createAjaxParams(options);
      params.url = options.url;
      _.extend(params, ajaxParams);
      $(options.formSelector).ajaxSubmit(params);
    });
  }

  function validateOption<ResponseExtensions, CustomHandlers>(
    name: keyof AjaxUnionOptions<ResponseExtensions, CustomHandlers>,
    options: AjaxUnionOptions<ResponseExtensions, CustomHandlers>
  ) {
    const option = options[name];
    if (typeof option === 'undefined') {
      throw "Missing option '" + name.toString() + "'";
    } else if (typeof option === 'string' && option.trim().length === 0) {
      throw "Option is an empty string '" + name.toString() + "'";
    }
  }

  function createAjaxParams<ResponseExtensions, CustomHandlers>(
    options: AjaxUnionOptions<ResponseExtensions, CustomHandlers>
  ): AjaxParams<ResponseExtensions> {
    return {
      dataType: options.dataType || 'json',
      success: function (response) {
        if (options.dataType === 'html' || response.status === 'success') {
          handleSuccess(options, response);
        } else if (response.status === 'error') {
          safeRollbarWarning(
            'Ajax helper received successful http status code but response body has status of error',
            { url: options.url }
          );
          handleError(options, response);
        } else {
          safeRollbarWarning(
            'Ajax helper received successful http status code but response body has status of ' +
              response.status,
            { url: options.url }
          );
          handleCustomStatus(options, response);
        }
        cleanup(options, response);
      },
      error: function (xhr) {
        if (
          xhr.responseJSON &&
          xhr.responseJSON.status !== 'error' &&
          options[xhr.responseJSON.status]
        ) {
          handleCustomStatus(options, xhr.responseJSON);
        } else {
          handleError(options, xhr.responseJSON, xhr);
        }
        cleanup(options, xhr.responseJSON);
      },
    };
  }

  function execute<ResponseExtensions, CustomHandlers>(
    options: AjaxUnionOptions<ResponseExtensions, CustomHandlers>,
    callback: () => void
  ) {
    const lockId = options.lockId;

    if (options.disableLock === true || acquireLock(lockId)) {
      disableButton(options);

      const validator = options.validator;
      if (typeof validator === 'function') {
        if (!validator.call().isValid()) {
          // eslint-disable-next-line max-depth
          if (options.scrollAfterValidation !== false) {
            Validate.scrollToFirstError();
          }
          cleanup(options);
          return;
        }
      }

      showPendingMessage(options);

      callback();
    }
  }

  function disableButton<ResponseExtensions, CustomHandlers>(
    options: AjaxUnionOptions<ResponseExtensions, CustomHandlers>
  ) {
    if (options.buttonSelector) {
      ButtonUtil.disable(options.buttonSelector);
    }
  }

  function enableButton<ResponseExtensions, CustomHandlers>(
    options: AjaxUnionOptions<ResponseExtensions, CustomHandlers>
  ) {
    if (options.buttonSelector) {
      ButtonUtil.enable(options.buttonSelector);
    }
  }

  function showPendingMessage<ResponseExtensions, CustomHandlers>(
    options: AjaxUnionOptions<ResponseExtensions, CustomHandlers>
  ) {
    if (typeof options.pendingMessage === 'string') {
      Flash.setPending(options.pendingMessage);
    } else if (
      options.pendingMessage !== null &&
      options.disableFlash !== true &&
      options.disablePending !== true
    ) {
      Flash.setPending(t('flash.saving'));
    }
  }

  function handleSuccess<ResponseExtensions, CustomHandlers>(
    options: AjaxUnionOptions<ResponseExtensions, CustomHandlers>,
    response: Response<ResponseExtensions>
  ) {
    if (typeof options.success === 'function') {
      options.success(response);
    }

    if (response.goto && options.redirect !== false) {
      assignLocation(response.goto);
    } else {
      if (options.successMessage) {
        Flash.setNotice(options.successMessage);
      } else if (response && response.message) {
        Flash.setNotice(response.message);
      } else {
        Flash.remove('.flash-pending');
      }
    }
  }

  function handleError<ResponseExtensions, CustomHandlers>(
    options: AjaxUnionOptions<ResponseExtensions, CustomHandlers>,
    response?: Response<ResponseExtensions>,
    xhr?: XHRResponse<ResponseExtensions>
  ) {
    if (typeof options.error === 'function') {
      options.error(response, xhr);
    }

    if (!options.disableFlash) {
      if (response && response.message) {
        Flash.setError(response.message);
      } else if (options.errorMessage) {
        Flash.setError(options.errorMessage);
      } else if (!options.hasOwnProperty('errorMessage')) {
        Flash.setError(t('flash.error'));
      } else {
        Flash.remove();
      }
    }
  }

  function handleCustomStatus<ResponseExtensions, CustomHandlers>(
    options: AjaxUnionOptions<ResponseExtensions, CustomHandlers>,
    response: Response<ResponseExtensions>
  ) {
    Flash.remove();

    const handler = options[response.status];
    if (typeof handler === 'function') {
      handler(response);
    } else {
      // eslint-disable-next-line no-console
      console.error('Missing handler for ' + response.status);
      safeRollbarWarning(
        'Ajax helper missing custom status handler for ' + response.status,
        { url: options.url }
      );
    }
  }

  function safeRollbarWarning(message: string, options: { url?: string }) {
    Rollbar.warning(message, options);
  }

  function cleanup<ResponseExtensions, CustomHandlers>(
    options: AjaxUnionOptions<ResponseExtensions, CustomHandlers>,
    response?: Response<ResponseExtensions>
  ) {
    if (typeof options.always === 'function') {
      options.always(response);
    }

    enableButton(options);
    if (options.lockId) {
      releaseLock(options.lockId);
    }
  }

  function acquireLock(lockId: string | undefined) {
    if (!lockId || locks[lockId]) {
      return false;
    }

    locks[lockId] = true;
    return true;
  }

  function releaseLock(lockId: string) {
    locks[lockId] = false;
  }

  return {
    get: get,
    post: post,
    del: del,
    put: put,
    submit: submit,
  };
})();

export default Ajax;
